import {
  forwardRef,
  memo,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';

import { Dialog } from '@headlessui/react';
import { FormikErrors, useFormik } from 'formik';

import Modal from 'components/Modal';
import ActionButton from 'components/modals/common/buttons';
import { LoaderContext } from 'contexts/LoaderContext';
import {
  RawRefundFormValues,
  refundFormProps,
  RefundFormValues,
} from 'interfaces';
import {
  cancelSubscriptionR,
  getRefundReasonsR,
} from 'repositories/Subscription';

import { round } from '../../helpers/numbers';
import { refundPaymentR } from '../../repositories/PaymentMethods';
import { RefundReasonsDictionary } from '../../types';

const validate = (values: RawRefundFormValues) => {
  const errors: FormikErrors<RefundFormValues> = {};
  const precision: number = values.precision;
  let partialRefundValue = values.partialRefundValue;
  const fullRefundValue: number | string = round(
    values.fullRefundValue,
    precision
  );
  partialRefundValue = round(partialRefundValue || 0, precision);

  if (values.refund == 'partialRefund') {
    if (!partialRefundValue) {
      errors.partialRefundValue = 'Required';
    } else if (!fullRefundValue) {
      errors.fullRefundValue = 'Required';
    } else if (partialRefundValue <= 0) {
      errors.partialRefundValue = 'Must be greater than 0';
    } else if (partialRefundValue > fullRefundValue) {
      errors.partialRefundValue = `Must be less or equal to ${fullRefundValue}`;
    }
  }

  if (values.refund == 'fullRefund') {
    if (!fullRefundValue) {
      errors.fullRefundValue = 'Should not be empty';
    } else if (fullRefundValue <= 0) {
      errors.fullRefundValue = 'Must be greater that 0';
    }
  }

  return errors;
};

let refundResultsDictionary: RefundReasonsDictionary;

function RefundForm({ formik, onCancel, isSubscActive }: refundFormProps) {
  const { errors, isSubmitting, touched } = formik;
  const { setIsLoading, addToast, isLoading } = useContext(LoaderContext);
  const [refundReasonsList, setRefundReasonsList] = useState([]);
  const inProgressRequest = useRef<Promise<void> | null>(null);
  const refundedAmount = Math.abs(formik.values.refundedAmount);
  const showFullRefund = refundedAmount == 0;
  const amountToRefund = formik.values.fullRefundValue - refundedAmount;

  useEffect(() => {
    if (!inProgressRequest.current) {
      inProgressRequest.current = (async () => {
        const result = (await getRefundReasonsR(
          setIsLoading,
          addToast
        )) as RefundReasonsDictionary;
        refundResultsDictionary = result;
        const refundList = Object.values(result);
        if (refundList.length) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          setRefundReasonsList(refundList);
        }
        inProgressRequest.current = null;
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      const precision = formik.values.precision;

      if (refundedAmount > 0) {
        await formik.setFieldValue(
          'partialRefundValue',
          round(amountToRefund, precision)
        );
        await formik.setFieldValue('refund', 'partialRefund');
      } else {
        const value = round(formik.values.fullRefundValue / 2, precision);
        await formik.setFieldValue('partialRefundValue', value);
      }
    })();
  }, [formik.values.precision, formik.values.fullRefundValue]);

  const handlePartialRefundChange = (
    value: React.ChangeEvent<HTMLInputElement>
  ) => {
    (async () => {
      const val = parseFloat(value.currentTarget.value);

      if (isNaN(val)) {
        return;
      }

      console.log(
        'formik.values.partialRefundValue',
        formik.values.partialRefundValue
      );
      await formik.setFieldValue(
        'partialRefundValue',
        round(val, formik.values.precision)
      );
    })();
  };

  const onClick = () => {
    void formik.submitForm();
  };

  const disabled =
    round(formik.values.partialRefundValue || 0, formik.values.precision) >
    round(formik.values.fullRefundValue, formik.values.precision);

  if (!isLoading) {
    return (
      <>
        <div
          className={'bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'}
          data-test-id="e2e-refund-modal"
        >
          <div className={'sm:flex sm:items-start'}>
            <button onClick={onCancel} className="absolute top-4 right-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className={'mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'}>
              <Dialog.Title
                as="h3"
                className={'text-base font-semibold leading-6 text-gray-900'}
              >
                Refund
              </Dialog.Title>

              <div className={'mt-2'}>
                <div className={'pt-4 pb-4'}>
                  <p>
                    Please review the information and select how you want to
                    proceed.
                  </p>
                </div>
                {isSubscActive && (
                  <div className="text-gray-600 pb-2">
                    The transaction is associated to an active subscription the
                    refund will also unsubscribe and expire the subscription.
                  </div>
                )}
                <div>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="w-full" style={{ paddingBottom: '10px' }}>
                      <div className="relative">
                        <select
                          className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                          onChange={formik.handleChange}
                          name="refundReason"
                          value={formik.values.refundReason}
                        >
                          <option
                            value=""
                            disabled
                            selected
                            hidden
                            style={{
                              fontSize: '11px',
                              fontStyle: 'italic',
                              color: 'grey',
                            }}
                          >
                            Please select a reason why you want to refund the
                            transaction
                          </option>
                          {refundReasonsList.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7 10l5 5 5-5H7z" fill="currentColor" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    {showFullRefund && (
                      <div
                        className={
                          'flex flex-row  flex-wrap items-center pl-4 p-4 border border-gray-200 rounded mt-2'
                        }
                      >
                        <div className={'flex-none basis-full mb-2'}>
                          <p>Full refund</p>
                        </div>

                        <div className={'flex-none'}>
                          <input
                            id="full-refund-radio"
                            name="refund"
                            type="radio"
                            defaultChecked={
                              formik.values.refund === 'fullRefund'
                            }
                            onChange={formik.handleChange}
                            value={'fullRefund'}
                            className={
                              'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 pr-2'
                            }
                          />
                        </div>
                        <div className={'basis-1/4'}>
                          <input
                            id="full-refund-value"
                            name="fullRefundValue"
                            type="number"
                            disabled={true}
                            onChange={formik.handleChange}
                            value={formik.values.fullRefundValue}
                            className={
                              'ml-2 items-center p-3 border border-gray-200 rounded'
                            }
                          />
                        </div>
                        <div className="mt-6">
                          <label className="flex items-center cursor-pointer relative">
                            <div className="relative">
                              <input
                                type="checkbox"
                                name="softFullRefund"
                                className="sr-only"
                                checked={
                                  formik.values.softFullRefund[0] === 'on'
                                }
                                onChange={formik.handleChange}
                              />
                              <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full"></div>
                            </div>
                            <div className="ml-3 text-gray-900 text-sm">
                              Switch on to keep the subscription and the feature
                              entitlement active after the refund. If the toggle
                              is off the subscription will be cancelled and the
                              feature entitlement removed after the refund
                            </div>
                          </label>
                        </div>
                      </div>
                    )}
                    <div
                      className={
                        'flex flex-row flex-wrap items-center pl-4 p-4 border border-gray-200 rounded mt-2'
                      }
                    >
                      <div className={'flex-none basis-full mb-2'}>
                        <p>Partial refund</p>
                      </div>
                      <div className={'flex-none'}>
                        <input
                          id="partial-refund-radio"
                          name="refund"
                          type="radio"
                          onChange={formik.handleChange}
                          value={'partialRefund'}
                          checked={
                            refundedAmount > 0 ||
                            formik.values.refund === 'partialRefund'
                          }
                          className={
                            'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300  pr-2'
                          }
                        />
                      </div>
                      <div className={'basis-1/4'}>
                        <input
                          id="partial-refund-value"
                          name="partialRefundValue"
                          type="number"
                          placeholder={'Put refund value here'}
                          onChange={formik.handleChange}
                          onBlur={handlePartialRefundChange}
                          disabled={formik.values.refund !== 'partialRefund'}
                          value={formik.values.partialRefundValue}
                          className={
                            'ml-2 items-center p-3 border border-gray-200 rounded'
                          }
                        />
                      </div>
                      {errors.fullRefundValue && touched.fullRefundValue && (
                        <div className={'basis-full'}>
                          <p className={'mt-2 text-sm text-red-600'}>
                            {errors.fullRefundValue}
                          </p>
                        </div>
                      )}
                      {errors.partialRefundValue &&
                        touched.partialRefundValue && (
                          <div className={'basis-full'}>
                            <p className={'mt-2 text-sm text-red-600'}>
                              {errors.partialRefundValue}
                            </p>
                          </div>
                        )}
                      <div className="mt-6">
                        <label className="flex items-center cursor-pointer relative">
                          <div className="relative">
                            <input
                              type="checkbox"
                              checked={
                                formik.values.softPartialRefund[0] === 'on'
                              }
                              name="softPartialRefund"
                              className="sr-only"
                              onChange={formik.handleChange}
                            />
                            <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full"></div>
                          </div>
                          <div className="ml-3 text-gray-900 text-sm">
                            Switch on to keep the subscription and the feature
                            entitlement active after the refund. If the toggle
                            is off the subscription will be cancelled and the
                            feature entitlement removed after the refund
                          </div>
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            'bg-gray-50 gap-x-2 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'
          }
        >
          <ActionButton
            dataTestId="e2e-refund-btn"
            onClick={onClick}
            disabled={isSubmitting || disabled}
            label={'Refund'}
          />
          <ActionButton
            dataTestId="e2e-dismiss-btn"
            onClick={onCancel}
            label={'Dismiss'}
          />
        </div>
      </>
    );
  }
}

const RefundFormMemo = memo(RefundForm);

interface RefundModalProps {
  paymentId?: string;
  paymentAmount: number;
  refundedAmount: number;
  cta_label: string | ReactElement;
  cta_styling: 'default' | 'no-styling';
  classForIcon?: string;
  disabled?: boolean;
  withoutButton?: boolean;
  subscriptionId?: string;
  isSubscActive?: boolean;
  updateRefundAmount:
    | ((transactionId: string, amount: number, precision: number) => void)
    | null;
  isForceCancel?: boolean;
  precision: number;
}

export type RefundModalRefType = {
  toggleModal: (value: boolean) => void;
};

const RefundModal = forwardRef<RefundModalRefType, RefundModalProps>(
  (props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = (value: boolean): void => setIsOpen(value);
    useImperativeHandle(ref, () => ({
      toggleModal,
    }));
    const {
      cta_label = null,
      cta_styling,
      paymentAmount,
      refundedAmount,
      paymentId,
      disabled,
      subscriptionId,
      isSubscActive,
      updateRefundAmount = null,
      isForceCancel,
      precision,
    } = props;
    const { setIsLoading, addToast } = useContext(LoaderContext);

    const initialValues: RefundFormValues = {
      fullRefundValue: paymentAmount,
      refundedAmount: refundedAmount,
      partialRefundValue: 0,
      refund: 'fullRefund',
      softFullRefund: [],
      softPartialRefund: [],
      precision: precision,
      refundReason: '',
    };

    const formSubmitHandler = async (values: RefundFormValues) => {
      const refundReason =
        Object.keys(refundResultsDictionary).find(
          (key) => refundResultsDictionary[key] === values.refundReason
        ) || null;
      const refundAmount = Number(
        round(
          values.refund === 'fullRefund'
            ? values.fullRefundValue
            : values.partialRefundValue,
          precision
        )
      );
      onCancelModal();
      setIsLoading(true);

      if (subscriptionId) {
        const result = await cancelSubscriptionR({
          subscriptionId,
          setIsLoading,
          addToast,
          refundAmount,
          paymentId,
          ...(isForceCancel ? { force: isForceCancel } : {}),
        });

        if (result && updateRefundAmount && paymentId) {
          updateRefundAmount(paymentId, refundAmount, precision);
        }

        return;
      }
      if (paymentId) {
        let isSoftRefund = false;

        if (values.refund === 'fullRefund') {
          isSoftRefund = values.softFullRefund[0] === 'on';
        } else {
          isSoftRefund = values.softPartialRefund[0] === 'on';
        }
        const result = await refundPaymentR(
          paymentId,
          refundAmount,
          setIsLoading,
          addToast,
          isSoftRefund,
          refundReason
        );

        if (result && updateRefundAmount) {
          updateRefundAmount(paymentId, refundAmount, precision);
        }
      }
    };

    const formik = useFormik({
      initialValues: initialValues,
      onSubmit: formSubmitHandler,
      validate: validate,
    });

    useEffect(() => {
      if (isOpen) {
        formik.resetForm();
      }

      void formik.setValues({
        refund: 'fullRefund',
        fullRefundValue: paymentAmount,
        refundedAmount: refundedAmount,
        partialRefundValue: 0,
        softFullRefund: [],
        softPartialRefund: [],
        precision: precision,
        refundReason: '',
      });
    }, [isOpen, paymentId, paymentAmount, precision, refundedAmount]);

    const onCancelModal = () => {
      toggleModal(false);
      formik.resetForm();
    };

    return (
      <>
        {!props.withoutButton && (
          <ActionButton
            dataTestId="e2e-refund-icon"
            onClick={() => toggleModal(true)}
            label={cta_label ? cta_label : 'Refund'}
            skipStyling={cta_styling == 'no-styling'}
            classForIcon={props.classForIcon}
            disabled={disabled}
          />
        )}
        <Modal
          isOpen={isOpen}
          onClose={() => toggleModal(false)}
          dataTestID="e2e-refund-payment-modal"
        >
          <RefundFormMemo
            formik={formik}
            onCancel={onCancelModal}
            isSubscActive={isSubscActive}
          />
        </Modal>
      </>
    );
  }
);

RefundModal.displayName = 'RefundModal';

export default RefundModal;
