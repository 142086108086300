import React, { useContext, useEffect, useState } from 'react';

import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon';
import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon';
import XCircleIcon from '@heroicons/react/20/solid/XCircleIcon';
import { ColumnDef, Row } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { DATE_FORMAT_TEMPLATE, DATE_FORMAT_TEMPLATE_YEAR } from 'api/constants';
import CBHTable from 'components/CBHTable';
import CancelSubscriptionModal from 'components/modals/CancelSubscriptionModal';
import { CustomerContext } from 'contexts/CustomerContext';
import {
  getSubscriptionStatusColor,
  getProductMapFromPricePoints,
  getSubscriptionStatus,
  getIsUnsubscribed,
} from 'helpers/subscriptions';
import { getSubscriptions } from 'services/customer';
import {
  pricePointType,
  SubscriptionEvent,
  SubscriptionStatuses,
  subscriptionType,
  PricePointsProductMapType,
  SubscriptionTags,
  subscriptionStateEnum,
  cancelSubscriptionOptions,
} from 'types';

import { SubscriptionPlan } from './common/SubscriptionPlan';
import ManageSubscriptionModal from './modals/ManageSubscriptionModal';
import PaymentMethod from './PaymentMethod';
import { SubscriptionLogs } from './SubscriptionLogs';
import { getNextPlanData } from '../common/Subscription';
import { getPrecisionByCurrencyCode } from '../helpers/numbers';

const getColumns: (
  updateRenewalDate: (date: Date | string, subscriptionId: string) => void
) => ColumnDef<subscriptionType>[] = (updateRenewalDate) => [
  {
    id: 'expander',
    header: () => null,
    cell: ({ row }) => {
      return (
        <button
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: { cursor: 'pointer' },
          }}
        >
          {row.getIsExpanded() ? (
            <ChevronDownIcon className={'w-5 h-5 text-gray-500'} />
          ) : (
            <ChevronRightIcon className={'w-5 h-5 text-gray-500'} />
          )}
        </button>
      );
    },
  },
  {
    header: 'Time (UTC)',
    cell: ({ row }) => {
      return <div style={{ minWidth: '100px' }}>{row.original.updatedAt}</div>;
    },
    footer: (props) => props.column.id,
  },
  {
    header: 'Product Name',
    cell: ({ row }) => {
      return (
        <div style={{ minWidth: '100px' }}>{row.original.productName}</div>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    header: 'Event Sub-Type',
    cell: ({ row }) => {
      const logs = row.original.logs;
      const logsLastEvent: SubscriptionEvent | null =
        logs && logs.length && logs[0] ? logs[0] : null;

      return <div>{logsLastEvent ? logsLastEvent.subtype : 'NA'}</div>;
    },
    footer: (props) => props.column.id,
  },
  {
    header: 'Plan',
    cell: ({ row }) => {
      const { pricePoints, pricePointId, pricePointVersionId, tags } =
        row.original;

      const pricePointData =
        pricePoints &&
        pricePoints.find(
          (pricePoint) =>
            pricePoint.id == pricePointId &&
            pricePoint.currentVersion == pricePointVersionId
        );

      const isTrial = tags?.includes(SubscriptionTags.isTrial);
      const isUpcoming = tags?.includes(SubscriptionTags.isUpcoming);
      const isCommitmentPeriod = tags.includes(SubscriptionTags.inCommitment);

      if (!pricePointData) {
        return <></>;
      }

      return (
        <SubscriptionPlan
          pricePointData={pricePointData}
          isTrial={isTrial}
          isUpcoming={isUpcoming}
          stacked={true}
          isCommitmentPeriod={isCommitmentPeriod}
        />
      );
    },
    footer: (props) => props.column.id,
  },
  {
    header: 'Event Price',
    cell: ({ row }) => {
      const logs = row.original.logs;
      const logsLastEvent = logs && logs.length && logs[0] ? logs[0] : null;

      return (
        <div>
          {logsLastEvent && logsLastEvent.transaction
            ? `${logsLastEvent.transaction.amount} ${logsLastEvent.transaction.currencyCode}`
            : 'NA'}
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    header: 'Statuses',
    cell: ({ row }) => {
      const {
        tags,
        currentPeriodEndAt,
        nextRenewCheck,
        pricePoints,
        pricePointId,
        pricePointVersionId,
        cancelReason,
      } = row.original;

      let expirationDate = '';

      const subStatus = getSubscriptionStatus(tags, cancelReason);

      if (
        currentPeriodEndAt &&
        getIsUnsubscribed(tags) &&
        subStatus !== SubscriptionStatuses.expired
      ) {
        expirationDate = currentPeriodEndAt;
      }

      const { plan: nextPlan, price: nextPrice } = getNextPlanData({
        pricePoints,
        tags,
        pricePointId,
        pricePointVersionId,
      });

      return (
        <div>
          <div>
            Subscription status: <br />
            <span className={getSubscriptionStatusColor(subStatus)}>
              {subStatus}
            </span>
          </div>
          {subStatus === SubscriptionStatuses.active && nextRenewCheck && (
            <div>
              Next renewal: {dayjs(nextRenewCheck).format(DATE_FORMAT_TEMPLATE)}
            </div>
          )}
          {nextPlan && <div>Next Plan: {nextPlan}</div>}
          {nextPrice && <div>Next Price: {nextPrice}</div>}

          {expirationDate && (
            <div>
              Subs Expiration: <br />
              {dayjs(expirationDate).format(DATE_FORMAT_TEMPLATE)}
            </div>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'unlink',
    header: '',
    cell: ({ row }) => {
      let paymentId = undefined;
      const { tags, pricePointId, pricePointVersionId, pricePoints } =
        row.original;

      if (row.original.logs && row.original.logs.length) {
        paymentId = row.original.logs[0]?.transaction?.popTransactionId;
      }
      const { plan: nextPlan, price: nextPrice } = getNextPlanData({
        pricePoints,
        tags,
        pricePointId,
        pricePointVersionId,
      });
      const [pricePointData] = pricePoints;
      const isCommitmentPeriod =
        pricePointData.commitmentPeriod >= 2 &&
        (tags.includes(SubscriptionTags.inCommitment) ||
          tags.includes(SubscriptionTags.isUnsubscriptionPostponed));

      const precision = getPrecisionByCurrencyCode(
        pricePointData?.currencyCode
      );
      const isSubscriptionModelButtonActive =
        row.original.state === subscriptionStateEnum.active ||
        tags.includes(SubscriptionTags.isUnsubscriptionPostponed);

      let cancelSubscriptionAvailableOptionsToUse = Object.keys(
        cancelSubscriptionOptions
      ) as Array<cancelSubscriptionOptions>;

      if (
        isCommitmentPeriod &&
        !tags.includes(SubscriptionTags.isUnsubscriptionPostponed)
      ) {
        cancelSubscriptionAvailableOptionsToUse = [
          cancelSubscriptionOptions.softCancel,
          cancelSubscriptionOptions.hardCancelWithoutRefund,
          cancelSubscriptionOptions.hardCancelWithRefund,
        ];
      } else if (
        isCommitmentPeriod &&
        tags.includes(SubscriptionTags.isUnsubscriptionPostponed)
      ) {
        cancelSubscriptionAvailableOptionsToUse = [
          cancelSubscriptionOptions.hardCancelWithoutRefund,
        ];
      }

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CancelSubscriptionModal
            subscriptionId={row.original.id}
            cta_styling={'no-styling'}
            cta_label={<XCircleIcon className={'w-8 h-8 text-red-700'} />}
            disabled={!isSubscriptionModelButtonActive}
            classForIcon="unsubscribe-logo-btn"
            productName={row.original.productName}
            nextRenewCheck={row.original.nextRenewCheck}
            paymentAmount={Number(row.original.orderAmount || 0)}
            refundAmount={Number(row.original.refundedAmount || 0)}
            updateRenewalDate={updateRenewalDate}
            updateRefundAmount={null}
            paymentId={paymentId}
            isCommitmentPeriod={isCommitmentPeriod}
            precision={precision}
            cancelSubscriptionAvailableOptions={
              cancelSubscriptionAvailableOptionsToUse
            }
          />
          <ManageSubscriptionModal
            paymentId={paymentId}
            subscriptionId={row.original.id}
            cta_styling={'no-styling'}
            nextRenewCheck={row.original.nextRenewCheck}
            refundAmount={row.original.orderAmount}
            updateRenewalDate={updateRenewalDate}
            updateRefundAmount={null}
            subscriptionTags={tags}
            nextPlan={nextPlan}
            nextPrice={nextPrice}
            productName={row.original.productName}
            expirationDate={row.original?.currentPeriodEndAt}
          />
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
];

const renderSubComponent = ({ row }: { row: Row<subscriptionType> }) => {
  const {
    logs,
    name,
    tags,
    updatedAt,
    nextRenewCheck,
    createdAt,
    id,
    pricePoints,
    pricePointId,
    pricePointVersionId,
    cancelReason,
  } = row.original;
  const subscriptionProperties = tags.reduce(
    (acc, curr) => acc + curr + ' ',
    ''
  );

  const logsLastEvent = logs && logs.length && logs[0] ? logs[0] : null;

  const subscriptionLogs = logs ? logs : [];

  const subStatus = getSubscriptionStatus(tags, cancelReason);

  const { plan: nextPlan, price: nextPrice } = getNextPlanData({
    pricePoints,
    tags,
    pricePointId,
    pricePointVersionId,
  });

  return (
    <>
      <div className="text-gray-500 text-left" style={{ border: '1px solid' }}>
        <div
          style={{ padding: '12px 8px 20px 8px', borderBottom: '1px solid' }}
        >
          <div
            style={{
              marginBottom: '15px',
              fontWeight: '600',
              color: '#000',
            }}
          >
            Subscription in Details:
          </div>
          <div
            className="flex"
            style={{ justifyContent: 'space-between', width: '60%' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>Created (UTC)</div>
              <div>Product:</div>
              <div>Subscription Id:</div>
              <div>Subscription Status:</div>
              <div>Subscription properties:</div>
              <div style={{ marginTop: '15px' }}>Last event type:</div>
              <div>Last event subtype:</div>
              <div>Last updated (utc):</div>
              {logsLastEvent && logsLastEvent.transaction && (
                <div>Payment method:</div>
              )}
              <div style={{ marginTop: '15px' }}>Next Charge Time:</div>
              {nextPlan && <div>Next Plan:</div>}
              {nextPrice && <div>Next Price:</div>}
            </div>
            <div
              className="vertical-devider"
              style={{ display: 'flex', flexDirection: 'column' }}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>{createdAt}</div>
              <div>{name}</div>
              <div className="whitespace-nowrap">{id}</div>
              <div className="whitespace-nowrap">
                {
                  <span className={getSubscriptionStatusColor(subStatus)}>
                    {subStatus}
                  </span>
                }
              </div>
              <div>{subscriptionProperties}</div>
              <div style={{ marginTop: '15px' }}>
                {logsLastEvent ? logsLastEvent.type : 'NA'}
              </div>
              <div>{logsLastEvent ? logsLastEvent.subtype : 'NA'}</div>
              <div>{updatedAt}</div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {logsLastEvent && logsLastEvent.transaction && (
                  <PaymentMethod
                    paymentMethodId={logsLastEvent.transaction.paymentMethodId}
                    paymentSchema={logsLastEvent.transaction.paymentSchema}
                    cardLast4={logsLastEvent.transaction.cardLast4}
                    paymentAccountId={
                      logsLastEvent.transaction.paymentAccountId
                    }
                    placement={'customer-subscriptions'}
                  />
                )}
              </div>

              <div style={{ marginTop: '15px' }}>
                {nextRenewCheck ? nextRenewCheck : 'NA'}
              </div>
              {nextPlan && <div>{nextPlan}</div>}
              {nextPrice && <div>{nextPrice}</div>}
            </div>
          </div>
        </div>
        <SubscriptionLogs logs={subscriptionLogs} />
      </div>
    </>
  );
};

const getProductNameFromPricePoint = (
  pricePointId: number,
  pricePointVersionId: number,
  pricePointProductMap: PricePointsProductMapType
) => {
  return (
    pricePointProductMap[pricePointId] &&
    pricePointProductMap[pricePointId][pricePointVersionId]
  );
};
export default function CustomerSubscriptions({
  pricePoints,
}: {
  pricePoints: Array<pricePointType>;
}) {
  const { customerId, customerType } = useContext(CustomerContext);
  const [subscriptions, setSubscriptions] = useState<Array<subscriptionType>>(
    []
  );

  const [pricePointsProductMap, setPricePointsProductMap] =
    useState<PricePointsProductMapType>({});

  const fetchSubscriptions = async (
    customerId: string,
    customerType: string,
    pricePoints: Array<pricePointType> = []
  ) => {
    const result = await getSubscriptions(customerId, customerType);

    if (result?.data) {
      const processedData = result.data.map(
        ({
          canceledAt,
          currentPeriodEndAt,
          currentPeriodStartAt,
          nextRenewCheck,
          updatedAt,
          logs,
          createdAt,
          ...subscription
        }: subscriptionType) => {
          const logsData =
            logs &&
            logs.map((event) => ({
              ...event,
              createdAt: event.createdAt
                ? dayjs(event.createdAt).format(DATE_FORMAT_TEMPLATE_YEAR)
                : event.createdAt,
            }));

          return {
            ...subscription,
            productName: getProductNameFromPricePoint(
              subscription.pricePointId,
              subscription.pricePointVersionId,
              pricePointsProductMap
            ),
            canceledAt: canceledAt
              ? dayjs(canceledAt).format(DATE_FORMAT_TEMPLATE_YEAR)
              : canceledAt,
            currentPeriodEndAt: currentPeriodEndAt
              ? dayjs(currentPeriodEndAt).format(DATE_FORMAT_TEMPLATE_YEAR)
              : currentPeriodEndAt,
            currentPeriodStartAt: currentPeriodStartAt
              ? dayjs(currentPeriodStartAt).format(DATE_FORMAT_TEMPLATE_YEAR)
              : currentPeriodStartAt,
            nextRenewCheck: nextRenewCheck
              ? dayjs(nextRenewCheck).format(DATE_FORMAT_TEMPLATE_YEAR)
              : nextRenewCheck,
            createdAt: createdAt
              ? dayjs(createdAt).format(DATE_FORMAT_TEMPLATE_YEAR)
              : createdAt,
            logs: logsData,
            updatedAt: updatedAt
              ? dayjs(updatedAt).format(DATE_FORMAT_TEMPLATE_YEAR)
              : updatedAt,
            pricePoints: pricePoints,
          };
        }
      );

      setSubscriptions(processedData);
    }
  };

  useEffect(() => {
    if (customerId && customerType && pricePointsProductMap) {
      (async () => {
        await fetchSubscriptions(customerId, customerType, pricePoints);
      })();
    }
  }, [customerId, pricePointsProductMap]);

  useEffect(() => {
    if (!pricePoints) {
      return;
    }

    setPricePointsProductMap(getProductMapFromPricePoints(pricePoints));
  }, [pricePoints]);

  const updateRenewalDate = (date: Date | string, subscriptionId: string) => {
    const updatedSubscriptions = subscriptions.map((subscription) => {
      if (subscription.id === subscriptionId) {
        return {
          ...subscription,
          nextRenewCheck: dayjs(date).format(DATE_FORMAT_TEMPLATE_YEAR),
        } as subscriptionType;
      }
      return subscription;
    });

    setSubscriptions(updatedSubscriptions);
  };

  return (
    <CBHTable
      data={subscriptions}
      columns={getColumns(updateRenewalDate)}
      getRowCanExpand={() => true}
      renderSubComponent={renderSubComponent}
      tHeadStyles={{ textWrap: 'nowrap' }}
      dataTestID={'e2e-customer-subscriptions-table'}
    />
  );
}
