import { ReactElement, useContext, useRef, useState } from 'react';

import Modal from 'components/Modal';
import ActionButton from 'components/modals/common/buttons';
import RefundIcon from 'components/RefundIcon';
import {
  cancelSubscriptionR,
  renewDateSubscription,
} from 'repositories/Subscription';

import CancelSubscriptionScreen from './common/CancelSubscriptionScreen';
import MoveRenewalDate from './common/MoveRenewalDate';
import RefundModal, { RefundModalRefType } from './RefundModal';
import { CustomerContext } from '../../contexts/CustomerContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { cancelSubscriptionOptions } from '../../types';

interface CancelSubscriptionModalProps {
  paymentId: string | undefined;
  subscriptionId: string;
  cta_label: string | ReactElement;
  cta_styling: 'default' | 'no-styling';
  classForIcon?: string;
  disabled?: boolean;
  productName?: string;
  nextRenewCheck?: string | null;
  paymentAmount: number;
  refundAmount: number;
  updateRenewalDate: (date: Date | string, subscriptionId: string) => void;
  updateRefundAmount:
    | ((transactionId: string, amount: number, precision: number) => void)
    | null;
  isCommitmentPeriod?: boolean;
  precision: number;
  cancelSubscriptionAvailableOptions?: cancelSubscriptionOptions[];
}

const CancelSubscriptionModal = (props: CancelSubscriptionModalProps) => {
  const [isOpenCancelSubscription, setIsOpenCancelSubscription] =
    useState(false);
  const [isOpenRenewDateSubscription, setIsRenewDateSubscription] =
    useState(false);
  const [isForceCancel, setIsForceCancel] = useState(false);
  const refundModalRef = useRef<RefundModalRefType>(null);

  const { isLoading, setIsLoading, addToast } = useContext(LoaderContext);

  const { refetchFeatures } = useContext(CustomerContext);

  const {
    cta_label = '',
    cta_styling,
    subscriptionId,
    disabled,
    productName,
    nextRenewCheck,
    updateRenewalDate,
    updateRefundAmount,
    paymentId,
    precision,
    cancelSubscriptionAvailableOptions,
  } = props;

  // @ts-expect-error - TS doesn't like Object keys being used as a type
  let cancelSubscriptionAvailableOptionsToUse: cancelSubscriptionOptions[] =
    cancelSubscriptionAvailableOptions;

  if (!cancelSubscriptionAvailableOptionsToUse) {
    cancelSubscriptionAvailableOptionsToUse = Object.keys(
      cancelSubscriptionOptions
    ) as [cancelSubscriptionOptions];
  }

  const onCancelSubscriptionConfirm = async (force?: boolean) => {
    setIsOpenCancelSubscription(false);
    await cancelSubscriptionR({
      subscriptionId,
      setIsLoading,
      addToast,
      force,
    });
  };

  const moveRenewalDate = async (
    values: Date | string,
    prolongFeatures: boolean
  ) => {
    setIsRenewDateSubscription(false);
    const result = await renewDateSubscription(
      values,
      subscriptionId,
      prolongFeatures,
      setIsLoading,
      addToast
    );
    if (result) {
      updateRenewalDate(values, subscriptionId);
    }
    refetchFeatures();
  };

  return (
    <>
      <ActionButton
        dataTestId="e2e-cancel-subscription-icon"
        onClick={() => setIsOpenCancelSubscription(true)}
        label={cta_label ? cta_label : 'Cancel Subscription'}
        skipStyling={cta_styling == 'no-styling'}
        classForIcon={props.classForIcon}
        disabled={disabled}
      />
      <Modal
        isOpen={isOpenRenewDateSubscription}
        onClose={() => setIsRenewDateSubscription(false)}
        dataTestID="e2e-move-renewal-date-modal"
      >
        {nextRenewCheck && (
          <MoveRenewalDate
            onConfirm={moveRenewalDate}
            isLoading={isLoading}
            onCancel={() => setIsRenewDateSubscription(false)}
            confirmCTACopy="Dismiss"
            dismissCTACopy="Move renewal date"
            productName={productName}
            nextRenewCheck={nextRenewCheck}
          />
        )}
      </Modal>
      <Modal
        isOpen={isOpenCancelSubscription}
        onClose={() => setIsOpenCancelSubscription(false)}
        dataTestID="e2e-cancel-subscription-modal"
      >
        {props.isCommitmentPeriod ? (
          <CancelSubscriptionScreen
            onConfirm={async (selectedOption) => {
              if (
                selectedOption === cancelSubscriptionOptions.softCancel ||
                selectedOption ===
                  cancelSubscriptionOptions.hardCancelWithoutRefund
              ) {
                const force =
                  selectedOption ===
                  cancelSubscriptionOptions.hardCancelWithoutRefund;
                await onCancelSubscriptionConfirm(force);
              }
              if (
                selectedOption ===
                cancelSubscriptionOptions.hardCancelWithRefund
              ) {
                setIsForceCancel(true);
                setIsOpenCancelSubscription(false);
                refundModalRef.current?.toggleModal(true);
              }
            }}
            isLoading={isLoading}
            onCancel={() => setIsOpenCancelSubscription(false)}
            confirmationMessage={
              'This is commitment subscription, you have the following options to cancel the subscription:'
            }
            title="Cancel Commitment Subscription"
            confirmCTACopy="Cancel Subscription"
            isCommitmentPeriod={props.isCommitmentPeriod}
            cancelSubscriptionAvailableOptions={
              cancelSubscriptionAvailableOptionsToUse
            }
          />
        ) : (
          <CancelSubscriptionScreen
            onConfirm={async (selectedOption) => {
              if (selectedOption === cancelSubscriptionOptions.withoutRefund) {
                await onCancelSubscriptionConfirm();
              }
              if (selectedOption === cancelSubscriptionOptions.withRefund) {
                setIsOpenCancelSubscription(false);
                refundModalRef.current?.toggleModal(true);
              }
            }}
            isLoading={isLoading}
            onCancel={() => setIsOpenCancelSubscription(false)}
            confirmationMessage={
              'Are you sure you want to cancel this subscription?'
            }
            title="Cancel Subscription"
            confirmCTACopy="Cancel Subscription"
            cancelSubscriptionAvailableOptions={
              cancelSubscriptionAvailableOptionsToUse
            }
          />
        )}
      </Modal>

      <RefundModal
        ref={refundModalRef}
        paymentId={paymentId}
        paymentAmount={Number(props.paymentAmount || '0')}
        cta_label={<RefundIcon className={'w-8 h-8 text-black-700'} />}
        cta_styling={'no-styling'}
        disabled={!props.refundAmount || Number(props.refundAmount) <= 0}
        classForIcon="refund-logo-btn"
        withoutButton={true}
        subscriptionId={subscriptionId}
        updateRefundAmount={updateRefundAmount}
        isForceCancel={isForceCancel}
        precision={precision}
        refundedAmount={Number(props.refundAmount || '0')}
      />
    </>
  );
};

CancelSubscriptionModal.displayName = 'CancelSubscriptionModal';

export default CancelSubscriptionModal;
