import axios from 'axios';

import { BASE_API_URL, BASE_AUTH_URL } from 'api/constants';

import { getToken, isAuth0Provider } from '../services/auth';
import { getActiveMerchantId } from '../services/cookie';

const auth_client = axios.create({
  baseURL: BASE_AUTH_URL,
  timeout: 5000,

  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

const private_client = axios.create({
  baseURL: BASE_API_URL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

private_client.interceptors.request.use(
  async (config) => {
    const token = await getToken();

    if (token) {
      if (isAuth0Provider()) {
        config.headers['pbp-jwt'] = `Bearer ${token}`;
        const activeMerchantId = getActiveMerchantId();
        config.headers['x-active-merchant-id'] = activeMerchantId?.toString();
        delete config.headers['jwt-access'];
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export { private_client, auth_client };
