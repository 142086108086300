import React, { memo, useContext, useEffect, useState } from 'react';

import { XCircleIcon } from '@heroicons/react/20/solid';
import ExclamationTriangleIcon from '@heroicons/react/20/solid/ExclamationTriangleIcon';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import * as UTCPlugin from 'dayjs/plugin/utc';
import { Decimal } from 'decimal.js';

import { DATE_FORMAT_TEMPLATE } from 'api/constants';
import {
  getIsExpiredBasedOnDate,
  getIsUnsubscribed,
  getSubscriptionStatus,
  getSubscriptionStatusColor,
} from 'helpers/subscriptions';

import DownLoadReceiptIcon from './DownLoadReceiptIcon';
import CancelSubscriptionModal from './modals/CancelSubscriptionModal';
import ActionButton from './modals/common/buttons';
import ManageSubscriptionModal from './modals/ManageSubscriptionModal';
import RefundModal from './modals/RefundModal';
import PaymentMethod from './PaymentMethod';
import RefundIcon from './RefundIcon';
import { SearchTable } from './SearchTable';
import { getNextPlanData } from '../common/Subscription';
import { CustomerContext } from '../contexts/CustomerContext';
import { getPrecisionByCurrencyCode, round } from '../helpers/numbers';
import { acceptDisputeR } from '../repositories/Dispute';
import { downloadReceiptR } from '../repositories/Search';
import {
  cancelSubscriptionOptions,
  CustomerContextState,
  CustomerTransactionListRefundRowTableOptions,
  DisputeStatuses,
  pricePointType,
  SearchTransactionLog,
  SubscriptionStatuses,
  SubscriptionTags,
} from '../types';

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(UTCPlugin.default); // use plugin

const daysForResolution = (date: dayjs.Dayjs) => {
  const now = dayjs().utc();

  return dayjs(date).diff(now, 'days');
};

export const getTransactionStatusColor = (status: string | undefined) => {
  switch (status) {
    case 'SETTLED':
    case 'AUTHORIZED':
      return 'text-green-500';
    case 'DECLINED':
    case 'PENDING':
      return 'text-red-500';
    default:
      return '';
  }
};

function normalizeString(snakeCaseString: string | undefined) {
  return (
    snakeCaseString &&
    snakeCaseString
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  );
}

const getDisputeStatusColor = (status: DisputeStatuses | undefined) => {
  switch (status) {
    case DisputeStatuses.early_fraud_warning:
    case DisputeStatuses.early_fraud_warning_refunded:
    case DisputeStatuses.first_chargeback_in_review:
    case DisputeStatuses.first_chargeback_pre_arbitration:
    case DisputeStatuses.pre_chargeback_alert_received:
    case DisputeStatuses.dispute_open:
    case DisputeStatuses.dispute_under_review:
    case DisputeStatuses.dispute_waiting_for_buyer_response:
    case DisputeStatuses.dispute_waiting_for_seller_response:
    case DisputeStatuses.chargeback_open:
    case DisputeStatuses.chargeback_under_review:
    case DisputeStatuses.chargeback_waiting_for_buyer_response:
    case DisputeStatuses.chargeback_waiting_for_seller_response:
    case DisputeStatuses.request_for_information:
    case DisputeStatuses.request_for_information_refunded:
    case DisputeStatuses.chargeback_under_review_internal:
    case DisputeStatuses.chargeback_waiting_for_buyer_response_internal:
      return 'text-[#FCAD15]'; // orange
    case DisputeStatuses.chargeback_accepted:
    case DisputeStatuses.dispute_accepted:
    case DisputeStatuses.first_chargeback_accepted:
    case DisputeStatuses.pre_chargeback_alert_accepted:
    case DisputeStatuses.first_chargeback_received:
    case DisputeStatuses.second_chargeback_received:
    case DisputeStatuses.first_chargeback_lost:
    case DisputeStatuses.pre_chargeback_alert_lost:
    case DisputeStatuses.dispute_lost:
    case DisputeStatuses.chargeback_lost:
      return 'text-[#C10F0F]'; // red
    case DisputeStatuses.first_chargeback_won:
    case DisputeStatuses.pre_chargeback_alert_won:
    case DisputeStatuses.chargeback_won:
    case DisputeStatuses.dispute_won:
      return 'text-[#068C04]'; // green
    default:
      return '';
  }
};

const columns: ColumnDef<SearchTransactionLog>[] = [
  {
    header: 'Time (UTC)',
    cell: ({ row }) => {
      const outputString = dayjs(row.original.createdAtUtc).format(
        DATE_FORMAT_TEMPLATE
      );
      return (
        <div style={{ display: 'flex', minWidth: '100px' }}>
          <span data-test-id="e2e-created-at">{outputString}</span>
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    header: 'Details',
    cell: ({ row }) => {
      const {
        productName,
        paymentMethod: PaymentMethodData,
        pspName,
        nextPeriodValue,
        nextPeriodType,
        subscriptionData,
        paymentMethodId,
        paymentSchema,
        cardLast4,
        paymentAccountId,
      } = row.original;

      const isTrial = subscriptionData?.isTrial || false;
      const isUpcoming =
        subscriptionData?.subscriptionTags?.includes(
          SubscriptionTags.isUpcoming
        ) || false;

      let planRow = null;

      if (isTrial && nextPeriodValue && nextPeriodType) {
        planRow = `Plan after intro period: ${nextPeriodValue} ${nextPeriodType}`;
      } else if (nextPeriodValue && nextPeriodType) {
        planRow = `Plan: ${nextPeriodValue} ${nextPeriodType}`;
      }

      if (!subscriptionData) {
        planRow = `Plan: not a subscription`;
      }

      if (isUpcoming) {
        planRow = null;
      }

      return (
        <div>
          <div>
            Product name:{' '}
            <span data-test-id="e2e-product-name">{productName}</span>
          </div>
          {planRow && (
            <div>
              <span data-test-id="e2e-plan">{planRow}</span>
            </div>
          )}
          {pspName && (
            <div>
              PSP: <span data-test-id="e2e-psp-name">{pspName}</span>
            </div>
          )}
          {PaymentMethodData && (
            <div>
              Payment method:
              <span data-test-id="e2e-payment-method" className={'cbh-hidden'}>
                {PaymentMethodData.name}
              </span>
              <PaymentMethod
                paymentMethodId={paymentMethodId}
                paymentSchema={paymentSchema}
                cardLast4={cardLast4}
                paymentAccountId={paymentAccountId}
                placement={'customer-transaction-list'}
              />
            </div>
          )}
        </div>
      );
    },
  },
  {
    header: 'Amounts',
    cell: ({ row }) => {
      const {
        priceLocal,
        refundedAmount,
        localCurrencyIsoCode,
        transactionStatus,
        subscriptionData,
        pricePointData,
      } = row.original;
      const positiveRefundedAmount = new Decimal(Math.abs(refundedAmount || 0));
      const priceLocalDecimal = new Decimal(priceLocal || 0);
      const precision = getPrecisionByCurrencyCode(localCurrencyIsoCode);
      const isUpcoming = subscriptionData?.subscriptionTags?.includes(
        SubscriptionTags.isUpcoming
      );
      const possibleRefundAmount =
        transactionStatus == 'DECLINED'
          ? new Decimal(0)
          : priceLocalDecimal
              .minus(positiveRefundedAmount)
              .toDecimalPlaces(precision);
      const positiveRefundedAmountStringified = positiveRefundedAmount
        .toDecimalPlaces(precision)
        .toString();
      const possibleRefundAmountStringified = possibleRefundAmount
        .toDecimalPlaces(precision)
        .toString();

      return (
        <div>
          {isUpcoming && (
            <>
              <div className="text-purple-600">
                Transaction:
                <span className="pl-2">
                  Upcoming value ({pricePointData.introBasePrice}{' '}
                  {pricePointData.currencyCode})
                </span>
              </div>
              <div>
                Refunded:
                <span className="text-xl text-gray-600 pl-2">
                  <span data-test-id="e2e-refunded-amount">
                    {positiveRefundedAmountStringified}
                  </span>{' '}
                  <span data-test-id="e2e-refunded-currency-code">
                    {pricePointData.currencyCode}
                  </span>
                </span>
              </div>
              <div className="text-amber-700">
                Possible Refund Amount:
                <span className="pl-2">N/A</span>
              </div>
            </>
          )}

          {!isUpcoming && (
            <>
              <div className="text-purple-600">
                Transaction:
                <span className="text-xl pl-2">
                  <span data-test-id="e2e-transaction-price">{priceLocal}</span>{' '}
                  <span data-test-id="e2e-transaction-currency-code">
                    {localCurrencyIsoCode}
                  </span>
                </span>
              </div>
              <div>
                Refunded:
                <span className="text-xl text-gray-600 pl-2">
                  <span data-test-id="e2e-refunded-amount">
                    {positiveRefundedAmountStringified}
                  </span>{' '}
                  <span data-test-id="e2e-refunded-currency-code">
                    {localCurrencyIsoCode}
                  </span>
                </span>
              </div>
              <div className="text-amber-700">
                Possible Refund Amount:
                <span className="text-xl pl-2">
                  <span data-test-id="e2e-possible-refund-amount">
                    {possibleRefundAmountStringified}
                  </span>{' '}
                  <span data-test-id="possible-refund-currency-code">
                    {localCurrencyIsoCode}
                  </span>
                </span>
              </div>
            </>
          )}
        </div>
      );
    },
  },
  {
    header: 'Statuses',
    cell: ({ row, table }) => {
      const {
        subscriptionData,
        transactionStatus,
        popTransactionType,
        priceLocal,
        refundedAmount,
        disputes,
        createdAtUtc,
        pbpPaymentId,
        pspResponseCode,
        productName,
        pricePointData,
        localCurrencyIsoCode,
      } = row.original;
      const paymentAmount = new Decimal(priceLocal || 0);
      const precision = getPrecisionByCurrencyCode(localCurrencyIsoCode);
      const paymentAmountNumbered = paymentAmount
        .toDecimalPlaces(precision)
        .toNumber();
      const refundedAmountNumbered = new Decimal(refundedAmount || 0)
        .toDecimalPlaces(precision)
        .toNumber();
      const positiveRefundedAmount = Math.abs(refundedAmount || 0);
      const [isAcceptDisabled, setIsAcceptDisabled] = useState<boolean>(false);
      const { refetchSearch } = useContext(CustomerContext);

      const tableOptions =
        table?.options as unknown as CustomerTransactionListRefundRowTableOptions;

      const updateRefundAmount = (
        transactionId: string,
        amount: number,
        precision: number
      ) => {
        if (tableOptions) {
          tableOptions.meta?.updateRefundAmount(
            transactionId,
            amount,
            precision
          );
        }
      };

      const tags = subscriptionData?.subscriptionTags as string[];
      const pricePoints = [pricePointData] as pricePointType[];
      const pricePointId = pricePointData?.id;
      const pricePointVersionId = pricePointData?.currentVersion;

      const { plan: nextPlan, price: nextPrice } = getNextPlanData({
        pricePoints,
        tags,
        pricePointId,
        pricePointVersionId,
      });

      const isSubscNotActive =
        subscriptionData?.subscriptionTags.includes(
          SubscriptionTags.isExpired
        ) ||
        subscriptionData?.subscriptionTags.includes(
          SubscriptionTags.isUnsubscribed
        );

      const isDeclinedTransaction = transactionStatus == 'DECLINED';
      const isSettledTransaction = transactionStatus == 'SETTLED';
      const isCancelSubscriptionModalDisabled =
        !subscriptionData || isSubscNotActive || isDeclinedTransaction;
      const { setIsLoading, addToast }: CustomerContextState =
        useContext(CustomerContext);
      const acceptDispute = (disputeId: string) => {
        setIsAcceptDisabled(true);
        (async () => {
          await acceptDisputeR(disputeId, setIsLoading, addToast);
          refetchSearch();
          setIsAcceptDisabled(false);
        })();
      };

      const downloadReceipt = async () => {
        const wasRefunded = refundedAmount !== 0;

        const createdUtc = wasRefunded ? undefined : createdAtUtc;
        const receipt = await downloadReceiptR(
          createdUtc,
          pbpPaymentId,
          setIsLoading,
          addToast
        );

        if (receipt) {
          const url = window.URL.createObjectURL(new Blob([receipt]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'receipts.zip');
          document.body.appendChild(link);
          link.click();
        }
      };

      let refundType = '';
      let expirationDate = '';
      if (
        popTransactionType === 'REFUND' &&
        refundedAmount &&
        refundedAmount !== 0
      ) {
        refundType =
          priceLocal === positiveRefundedAmount
            ? ' (full refund)'
            : ' (partial refund)';
      }

      const isExpiredByDate = getIsExpiredBasedOnDate(
        subscriptionData?.subscriptionPeriodEndAt
      );
      const isCommitmentPeriod =
        pricePointData.commitmentPeriod >= 2 &&
        subscriptionData &&
        (subscriptionData.subscriptionTags.includes(
          SubscriptionTags.inCommitment
        ) ||
          subscriptionData.subscriptionTags.includes(
            SubscriptionTags.isUnsubscriptionPostponed
          ));

      let cancelSubscriptionAvailableOptionsToUse = Object.keys(
        cancelSubscriptionOptions
      ) as Array<cancelSubscriptionOptions>;

      if (
        isCommitmentPeriod &&
        !subscriptionData.subscriptionTags.includes(
          SubscriptionTags.isUnsubscriptionPostponed
        )
      ) {
        cancelSubscriptionAvailableOptionsToUse = [
          cancelSubscriptionOptions.softCancel,
          cancelSubscriptionOptions.hardCancelWithoutRefund,
          cancelSubscriptionOptions.hardCancelWithRefund,
        ];
      } else if (
        isCommitmentPeriod &&
        subscriptionData.subscriptionTags.includes(
          SubscriptionTags.isUnsubscriptionPostponed
        )
      ) {
        cancelSubscriptionAvailableOptionsToUse = [
          cancelSubscriptionOptions.hardCancelWithoutRefund,
        ];
      }

      const subStatus =
        subscriptionData === null
          ? SubscriptionStatuses.notASubscription
          : getSubscriptionStatus(
              subscriptionData?.subscriptionTags,
              subscriptionData?.cancelReason
            );

      if (
        subscriptionData?.subscriptionPeriodEndAt &&
        getIsUnsubscribed(subscriptionData?.subscriptionTags) &&
        subStatus !== SubscriptionStatuses.expired
      ) {
        expirationDate = subscriptionData?.subscriptionPeriodEndAt;
      }
      return (
        <div className={'relative'}>
          {disputes && disputes.length > 0 && (
            <ExclamationTriangleIcon
              className={
                'w-8 h-8 text-red-700 absolute left-[-40px] top-[10px]'
              }
            />
          )}
          <div className="flex flex-row items-center">
            <div className={'flex-auto'}>
              <div>
                Subscription status: <br />
                <span
                  className={`${getSubscriptionStatusColor(subStatus)}`}
                  data-test-id="e2e-subscription-status"
                >
                  {subStatus}
                </span>
              </div>
              {subStatus === SubscriptionStatuses.active &&
                subscriptionData?.subscriptionNextCheck &&
                !isExpiredByDate && (
                  <div>
                    Next renewal:{' '}
                    <span data-test-id="e2e-next-renewal">
                      {dayjs(subscriptionData?.subscriptionNextCheck).format(
                        DATE_FORMAT_TEMPLATE
                      )}
                    </span>
                  </div>
                )}
              {expirationDate && (
                <div>
                  Subs Expiration: <br />
                  <span data-test-id="e2e-subscription-expiration-date">
                    {dayjs(expirationDate).format(DATE_FORMAT_TEMPLATE)}
                  </span>
                </div>
              )}
              <div className={'pt-2 has-tooltip flex flex-row items-center'}>
                Transaction status:{' '}
                <span
                  data-test-id="e2e-transaction-status"
                  className={getTransactionStatusColor(transactionStatus)}
                >
                  {transactionStatus}
                </span>
                {pspResponseCode && transactionStatus === 'DECLINED' && (
                  <span
                    className={
                      'tooltip rounded shadow-lg mb-16 p-1 bg-[#e3e3e3] text-[#333]'
                    }
                  >
                    Reason code: {pspResponseCode}
                  </span>
                )}
              </div>
              <div>
                Transaction type:{' '}
                <span data-test-id="e2e-pop-transaction-type">
                  {popTransactionType}
                </span>
                <span data-test-id="e2e-refund-type">{refundType}</span>
              </div>
            </div>

            <div className={'flex-initial w-20'}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CancelSubscriptionModal
                  paymentId={row.original.popTransactionId}
                  subscriptionId={subscriptionData?.subscriptionId as string}
                  cta_styling={'no-styling'}
                  cta_label={<XCircleIcon className={'w-8 h-8 text-red-700'} />}
                  disabled={isCancelSubscriptionModalDisabled}
                  classForIcon="unsubscribe-logo-btn"
                  nextRenewCheck={
                    row.original.subscriptionData?.subscriptionNextCheck
                  }
                  paymentAmount={row.original.priceLocal || 0}
                  refundAmount={row.original.refundedAmount || 0}
                  updateRenewalDate={() => {}}
                  updateRefundAmount={updateRefundAmount}
                  isCommitmentPeriod={isCommitmentPeriod}
                  precision={precision}
                  cancelSubscriptionAvailableOptions={
                    cancelSubscriptionAvailableOptionsToUse
                  }
                />
                <ManageSubscriptionModal
                  paymentId={row.original.popTransactionId}
                  subscriptionId={subscriptionData?.subscriptionId as string}
                  cta_styling={'no-styling'}
                  nextRenewCheck={
                    row.original.subscriptionData?.subscriptionNextCheck
                  }
                  expirationDate={subscriptionData?.subscriptionPeriodEndAt}
                  refundAmount={row.original.priceLocal?.toString()}
                  updateRenewalDate={() => {}}
                  updateRefundAmount={updateRefundAmount}
                  productName={productName}
                  nextPlan={nextPlan}
                  nextPrice={nextPrice}
                  currencyCode={row.original.localCurrencyIsoCode}
                  subscriptionTags={subscriptionData?.subscriptionTags}
                />
              </div>
              <RefundModal
                paymentId={row.original.popTransactionId}
                paymentAmount={paymentAmountNumbered}
                cta_label={<RefundIcon className={'w-8 h-8 text-black-700'} />}
                cta_styling={'no-styling'}
                disabled={
                  !row.original.priceLocal ||
                  row.original.priceLocal <= 0 ||
                  !row.original.pspTransactionId ||
                  row.original.priceLocal <= positiveRefundedAmount ||
                  !row.original.isRefundable
                }
                classForIcon="refund-logo-btn"
                isSubscActive={!isSubscNotActive}
                updateRefundAmount={updateRefundAmount}
                precision={precision}
                refundedAmount={refundedAmountNumbered}
              />
              <ActionButton
                onClick={downloadReceipt}
                label={<DownLoadReceiptIcon className="w-8 h-8" />}
                skipStyling
                dataTestId="e2e-unlink-payment-method-btn"
                disabled={!isSettledTransaction}
                classForIcon={'download-receipt-logo-btn'}
              />
            </div>
          </div>

          {disputes &&
            disputes.map((dispute) => (
              <div
                key={dispute.id}
                className={'pt-2 has-tooltip flex flex-row items-center'}
              >
                <div className={'flex-auto'}>
                  <span
                    className={
                      'tooltip rounded shadow-lg p-1 bg-[#e3e3e3] text-[#333] -mt-10'
                    }
                  >
                    {dispute.statusDescription}
                  </span>
                  <div>
                    Dispute status: <br />
                    <span
                      className={
                        'font-semibold ' +
                        `${getDisputeStatusColor(dispute.status)}`
                      }
                    >
                      {normalizeString(dispute.status)}
                    </span>
                  </div>
                  <div>
                    Disputed on:{' '}
                    <span>
                      {dayjs(dispute.pspCreatedAt).format(DATE_FORMAT_TEMPLATE)}
                    </span>
                  </div>
                  <div>
                    Reason: <span>{normalizeString(dispute.reason)}</span>
                  </div>
                  {dispute.evidenceDueBy && (
                    <div>
                      Time to resolution:{' '}
                      <span>
                        {daysForResolution(dayjs(dispute.evidenceDueBy))}
                      </span>{' '}
                      days till results
                    </div>
                  )}
                </div>
                <div className={'flex-initial w-20'}>
                  <ActionButton
                    onClick={() => {
                      acceptDispute(dispute.id);
                    }}
                    label={'Accept Dispute'}
                    disabled={!dispute.isAcceptable || isAcceptDisabled}
                    additionalStyles={{
                      marginTop: '2px',
                      marginBottom: '2px',
                      paddingTop: '2px',
                      paddingBottom: '2px',
                    }}
                  />

                  {dispute.detailsUrl && (
                    <ActionButton
                      onClick={() => {
                        window.open(dispute.detailsUrl, '_blank');
                      }}
                      label={'See dispute'}
                      additionalStyles={{
                        marginTop: '2px',
                        marginBottom: '2px',
                        paddingTop: '2px',
                        paddingBottom: '2px',
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      );
    },
  },
];

type CustomersTransactionsListProps = {
  customersTransactions: Array<SearchTransactionLog>;
};
export const CustomersTransactionsList = memo(
  function CustomersTransactionsList({
    customersTransactions,
  }: CustomersTransactionsListProps) {
    const [logsData, setLogsData] = useState<Array<SearchTransactionLog>>([]);

    useEffect(() => {
      setLogsData(
        customersTransactions.map((event) => ({
          ...event,
          createdAtUtc: event.createdAtUtc,
        }))
      );
    }, [customersTransactions]);

    const updateRefundAmount = (
      transactionId: string,
      amount: number,
      precision: number
    ) => {
      setLogsData((old) =>
        old.map((row) => {
          if (row.popTransactionId == transactionId) {
            if (row?.refundedAmount) {
              row.refundedAmount = Number(
                round(
                  Math.abs(Number(row.refundedAmount)) + Number(amount),
                  precision
                )
              );
            }
          }
          return row;
        })
      );
    };

    return (
      <SearchTable
        data={logsData}
        columns={columns}
        getRowCanExpand={() => false}
        updateRefundAmount={updateRefundAmount}
      />
    );
  }
);
