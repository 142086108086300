import React, { useContext } from 'react';

import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { DATE_FORMAT_TEMPLATE } from 'api/constants';

import DownLoadReceiptIcon from './DownLoadReceiptIcon';
import ActionButton from './modals/common/buttons';
import { Table } from './TableLogs';
import { CustomerContext } from '../contexts/CustomerContext';
import { downloadReceiptR } from '../repositories/Search';
import { CustomerContextState, TransactionLogType } from '../types';

const columns: ColumnDef<TransactionLogType>[] = [
  {
    accessorKey: 'createdAt',
    header: 'Timestamp (UTC)',
    cell: ({ row }) => {
      const outputString = dayjs(row.original.createdAtUtc).format(
        DATE_FORMAT_TEMPLATE
      );
      return (
        <div
          style={{
            minWidth: '90px',
          }}
        >
          {outputString}
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    accessorKey: 'popTransactionType',
    header: 'Type',
    cell: ({ row }) => (
      <div
        style={{
          paddingLeft: `${row.depth * 2}rem`,
        }}
      >
        {row.original.popTransactionType
          ? row.original.popTransactionType
          : '-'}
      </div>
    ),
    footer: (props) => props.column.id,
  },
  {
    accessorKey: 'transactionStatus',
    header: 'Event',
    cell: ({ row }) => {
      const { pspResponseCode, transactionStatus } = row.original;

      return (
        <div className={'pt-2 has-tooltip flex flex-row items-center'}>
          {pspResponseCode && transactionStatus === 'DECLINED' && (
            <span
              className={
                'tooltip rounded shadow-lg mb-16 p-1 bg-[#e3e3e3] text-[#333]'
              }
            >
              Reason code: {pspResponseCode}
            </span>
          )}
          <span data-test-id="e2e-transaction-status">
            {transactionStatus ? transactionStatus : '-'}
          </span>
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    accessorKey: 'amount',
    header: 'Price',
    cell: ({ row }) => {
      return (
        <div>
          {row.original.amount} {row.original.currencyCode}
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    accessorKey: 'download receipt',
    header: '',
    cell: ({ row }) => {
      const { transactionStatus, createdAtUtc, id } = row.original;
      const isSettledTransaction = transactionStatus == 'SETTLED';
      const { setIsLoading, addToast }: CustomerContextState =
        useContext(CustomerContext);
      const downloadReceipt = async () => {
        const receipt = await downloadReceiptR(
          createdAtUtc,
          id,
          setIsLoading,
          addToast
        );

        if (receipt) {
          const outputString = dayjs(createdAtUtc).format('DD-MMM-YY');
          const url = window.URL.createObjectURL(new Blob([receipt]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `transaction_receipt_${outputString}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        }
      };

      return (
        <ActionButton
          onClick={downloadReceipt}
          label={<DownLoadReceiptIcon className="w-8 h-8" />}
          skipStyling
          dataTestId="e2e-unlink-payment-method-btn"
          disabled={!isSettledTransaction}
          classForIcon={'download-receipt-logo-btn'}
        />
      );
    },
    footer: (props) => props.column.id,
  },
];

interface PaymentTransactionLogsProps {
  transactionLogs: Array<TransactionLogType>;
  id: string;
}
export default function PaymentTransactionLogs(
  props: PaymentTransactionLogsProps
) {
  const transactionLogs = props.transactionLogs.map(
    ({ ...subscription }: TransactionLogType) => {
      return {
        ...subscription,
        id: props.id,
      };
    }
  );

  return (
    <Table
      data={transactionLogs}
      columns={columns}
      getRowCanExpand={() => true}
      tableStyles={{
        padding: '5px 5px 15px 5px',
        color: 'black',
        fontWeight: '600',
      }}
      tHeadStyle={{ width: '25%' }}
      tableTitle="Transaction Lifecycle - Events"
    />
  );
}
