import { AxiosError, AxiosResponse, isAxiosError } from 'axios';

import { private_client } from 'api/client';
import {
  CBH_URLS,
  DEFAULT_CANCELLATION_REASON,
  DEFAULT_CANCELLATION_TYPE,
  SEARCH_CUSTOMERS_TRANSACTIONS_TIMEOUT,
} from 'api/constants';
import {
  CBHPrivateClientResponse,
  CBHResponseData,
  CBHResponseError,
  customerFieldsParams,
  ErrorMappingType,
  featureResponse,
  paymentMethodType,
  PricePointPaymentResponse,
  pricePointResponse,
  revokeFeatureRequestParams,
  searchCustomersParams,
  subscriptionResponse,
  refundPaymentParams,
  cancelSubscriptionParams,
  unlinkPaymentMethodParams,
  customersType,
  TransactionsForCustomersResponse,
  ShortPricePoint,
  downloadReceiptRequestParams,
  acceptDisputeParams,
  refundReasonsResponse,
  freebiesResponse,
  addFreebieResponse,
  customerTypeVariants,
} from 'types';

const ERROR_MAPPING: ErrorMappingType = {
  'pbp.validation_error': 'One or more request parameters is invalid',
  'pbp_customer_billing_history.cancel_subscription.cancelation_external_subscription_not_permitted':
    'Cancelling of external subscription is not permitted',
  'pbp_customer_billing_history.cancel_subscription.cancelation_failed':
    'It seems like cancellation has been already started',
  'pbp_customer_billing_history.cancel_subscription.no_rights':
    'You have no right to perform this operation',
  'pbp_customer_billing_history.cancel_subscription.subscription_not_found':
    'Subscription was not found',
  'pbp_customer_billing_history.customer_id_not_found': 'Customer id not found',
  'pbp_customer_billing_history.primer_error': 'Something went wrong',
  'pbp_customer_billing_history.refund_amount_more_than_payment_amount':
    'Refund amount more than payment amount',
  'pbp_customer_billing_history.unlink_payment_method.customer_has_active_subscriptions':
    'Customer has active subscriptions',
  'pbp_customer_billing_history.dispute_not_found': 'Dispute Not Found',
  'pbp_customer_billing_history.dispute_already_accepted':
    'Dispute Already Accepted',
  'pbp_customer_billing_history.dispute_has_no_merchant_info':
    'Dispute Has No Merchant Info',
  'pbp_customer_billing_history.dispute_accept_forbidden':
    'Dispute Accept Forbidden',
  'pbp_customer_billing_history.dispute_refund_amount_more_than_payment_amount':
    'Dispute Refund Amount More Than Payment Amount',
  'pbp_customer_billing_history.dispute_already_refunded':
    'Dispute Already Refunded',
  'pbp_customer_billing_history.dispute_close_failed': 'Dispute Close Failed',
  'pbp_customer_billing_history.pause.period_too_long': 'Period Too Long',
  'pbp_customer_billing_history.pause.date_in_the_past': 'Date In The Past',
  'pbp_customer_billing_history.pause.subscription_in_grace_or_retries':
    'Subscription In Grace Or Retries',
  'pbp_customer_billing_history.pause.subscription_expired':
    'Subscription Expired',
  'pbp_customer_billing_history.pause.subscription_is_deferred':
    'Subscription Is Deferred',
  'pbp_customer_billing_history.pause.subscription_is_paused':
    'Subscription Is Paused',
  'pbp_customer_billing_history.currency_is_not_correct':
    'Currency Is Not Correct',
  'pbp_customer_billing_history.can_not_cancel_discount':
    'Can Not Cancel Discount',
  'pbp_customer_billing_history.discount_already_exists':
    'Discount Already Exists',
  'pbp_customer_billing_history.subscription_discount_customer_mismatch':
    'Subscription Discount Customer Mismatch',
  'pbp_customer_billing_history.already_refunded':
    'The Transaction Has Been Already Refunded',
  'pbp_customer_billing_history.cannot_find_payment_for_refund':
    'Cannot Find Payment For Refund',
  'pbp_customer_billing_history.subscription_not_found':
    'Subscription Has Not Found',
  'pbp_customer_billing_history.subscription_not_active':
    'Subscription Is Not Active',
  'pbp_customer_billing_history.subscription_not_belong_to_merchant':
    'Subscription Does Not Belong To Merchant',
  'pbp_customer_billing_history.unlink_payment_method.payment_method_not_found':
    'Payment Method Not Found',
  'pbp_customer_billing_history.revoke_feature_error': 'Revoke Feature Error',
  'pbp_customer_billing_history.change_feature_error': 'Change Feature Error',
  'pbp_customer_billing_history.defer_subscription.date_in_past':
    'Date In The Past',
  'pbp_customer_billing_history.defer_subscription.subscription_in_grace_or_retries':
    'Subscription Is In Grace Or Retries',
  'pbp_customer_billing_history.defer_subscription.subscription_is_paused':
    'Subscription Is Paused',
  'pbp_customer_billing_history.refund_failed': 'Refund Failed',
  transaction_not_found: 'Transaction Is Not Found',
  'pbp_customer_billing_history.subscription_in_renewal':
    'Subscription Is In Renewal',
  'pbp_customer_billing_history.hard_reset.subscription_not_expired':
    'Subscription Is Not Expired',
  'pbp_customer_billing_history.hard_reset.subscription_not_found':
    'Subscription Is Not Found',
  'pbp_customer_billing_history.soft_reset.soft_reset_subscription_expired':
    'Subscription Is Expired',
  'pbp_customer_billing_history.soft_reset.soft_reset_subscription_not_found':
    'Subscription Is Not Found',
  'pbp_customer_billing_history.soft_reset_subscription_has_upcoming_migration':
    'Subscription Has Upcoming Migration',
  something_wrong: 'Something went wrong',
};

export const get_error_from_mapping = (
  error: string,
  default_error: string
): string => {
  if (error in ERROR_MAPPING) {
    return ERROR_MAPPING[error];
  } else {
    return default_error;
  }
};

const secured_private_client = async (
  request: object,
  data_key?: string | undefined
): Promise<CBHPrivateClientResponse> => {
  try {
    const response: AxiosResponse<CBHResponseData> = await private_client(
      request
    );

    const data = data_key !== undefined ? response.data[data_key] : null;

    return { data: data, error: null };
  } catch (err) {
    const error = (err as Error) || AxiosError<CBHResponseError>;
    if (isAxiosError(error)) {
      if (error.response) {
        const response = error.response?.data as CBHResponseError;
        // The request was made and the server responded with a status code
        return {
          data: null,
          error: get_error_from_mapping(
            String(response.code) || ERROR_MAPPING.something_wrong,
            ERROR_MAPPING.something_wrong
          ),
        };
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        return {
          data: null,
          error: get_error_from_mapping(
            ERROR_MAPPING.something_wrong,
            ERROR_MAPPING.something_wrong
          ),
        };
      }
    }

    // Something happened in setting up the request that triggered an Error
    return {
      data: null,
      error: get_error_from_mapping(
        ERROR_MAPPING.something_wrong,
        ERROR_MAPPING.something_wrong
      ),
    };
  }
};

const getCustomerPaymentMethods = async ({
  customerId,
  customerType,
}: customerFieldsParams): Promise<
  AxiosResponse<CBHResponseData<Array<paymentMethodType>>>
> => {
  return private_client({
    data: {
      customerId: {
        type: customerType,
        value: customerId,
      },
    },
    method: 'post',
    url: CBH_URLS.payment_methods_url,
  });
};

const refundPayment = async ({
  paymentId,
  amount,
  isSoftRefund,
  reason,
}: refundPaymentParams): Promise<AxiosResponse<CBHResponseData>> => {
  return private_client({
    data: {
      amount: amount,
      paymentId: paymentId,
      reason,
      isSoftRefund,
    },
    method: 'post',
    url: CBH_URLS.refund_payment_url,
  });
};

const cancelSubscription = async ({
  subscriptionId,
  refundAmount,
  paymentId,
  force,
}: cancelSubscriptionParams): Promise<AxiosResponse<CBHResponseData>> => {
  const data = {
    cancelationReason: DEFAULT_CANCELLATION_REASON,
    cancelationType: DEFAULT_CANCELLATION_TYPE,
    subscriptionId: subscriptionId,
    force: force || true,
    ...(refundAmount && { refundAmount }),
    ...(paymentId && { paymentId }),
  };
  return private_client({
    data,
    method: 'post',
    url: CBH_URLS.cancel_subscription,
  });
};
export const changeRenewalDate = async (body: {
  isoDate: string;
  subscriptionId: string;
  prolongFeatures: boolean;
}) => {
  return private_client({
    data: {
      deferDate: body.isoDate,
      subscriptionId: body.subscriptionId,
      prolongFeatures: body.prolongFeatures,
    },
    method: 'post',
    url: CBH_URLS.defer_subscription,
  });
};

export const pauseSubscription = async (body: {
  isoDate: string;
  subscriptionId: string;
}) => {
  return private_client({
    data: {
      pauseUntil: body.isoDate,
      subscriptionId: body.subscriptionId,
    },
    method: 'post',
    url: CBH_URLS.pause_subscription,
  });
};

export const setRenewalDiscount = async (body: {
  id: string;
  amount: string;
  subscriptionId: string;
}) => {
  return private_client({
    data: {
      id: body.id,
      amount: body.amount,
      subscriptionId: body.subscriptionId,
    },
    method: 'post',
    url: CBH_URLS.set_renewal_discount,
  });
};

export const hardRestartSubscription = async (body: {
  subscriptionId: string;
}) => {
  return private_client({
    data: {
      subscriptionId: body.subscriptionId,
    },
    method: 'post',
    url: CBH_URLS.hard_reset,
  });
};

export const softRestartSubscription = async (body: {
  subscriptionId: string;
}) => {
  return private_client({
    data: {
      subscriptionId: body.subscriptionId,
    },
    method: 'post',
    url: CBH_URLS.soft_reset,
  });
};

const getFeatures = async (
  customerId: string,
  customerType: string
): Promise<featureResponse> => {
  return await secured_private_client(
    {
      data: {
        customerId: {
          type: customerType,
          value: customerId,
        },
      },
      method: 'post',
      url: CBH_URLS.get_features,
    },
    'features'
  );
};
const getRefundReasons = async (): Promise<
  AxiosResponse<refundReasonsResponse>
> => {
  return private_client.post(CBH_URLS.refund_reasons);
};

const getSubscriptions = async (
  customerId: string,
  customerType: string
): Promise<subscriptionResponse> => {
  return await secured_private_client(
    {
      data: {
        customerId: {
          type: customerType,
          value: customerId,
        },
      },
      method: 'post',
      url: CBH_URLS.get_subscriptions,
    },
    'subscriptions'
  );
};

const unlinkPaymentMethod = async ({
  customerId,
  customerType,
  paymentMethodToken,
}: unlinkPaymentMethodParams): Promise<AxiosResponse<CBHResponseData>> => {
  return private_client({
    data: {
      customerId: {
        type: customerType,
        value: customerId,
      },
      paymentMethodToken: paymentMethodToken,
    },
    method: 'post',
    url: CBH_URLS.unlink_payment_method,
  });
};

const getPricePoints = async (
  customerId: string,
  customerType: string
): Promise<pricePointResponse> => {
  return await secured_private_client(
    {
      data: {
        customerId: {
          type: customerType,
          value: customerId,
        },
      },
      method: 'post',
      url: CBH_URLS.get_price_points,
    },
    'pricePoints'
  );
};

const getPricePointPayments = async (
  customerId: string,
  customerType: string,
  pricePoints: Array<ShortPricePoint>
): Promise<PricePointPaymentResponse> => {
  return await secured_private_client(
    {
      data: {
        customerId: {
          type: customerType,
          value: customerId,
        },
        pricePoints: pricePoints,
        pricePointId: pricePoints[0]?.id,
        pricePointVersionId: pricePoints[0]?.versionId,
      },
      method: 'post',
      url: CBH_URLS.get_payments_by_price_point,
    },
    'payments'
  );
};

const searchCustomers = async ({
  searchString,
  from,
  to,
}: searchCustomersParams): Promise<
  AxiosResponse<CBHResponseData<Array<customersType>>>
> => {
  return private_client.post(CBH_URLS.search_customers, {
    searchString: searchString,
    transactionDateMin: from,
    transactionDateMax: to,
  });
};

const searchCustomersTransactions = async (
  merchantsIds: string[]
): Promise<AxiosResponse<TransactionsForCustomersResponse>> => {
  return private_client.post(
    CBH_URLS.list_transactions_for_customers,
    {
      customerIds: merchantsIds,
    },
    {
      timeout: SEARCH_CUSTOMERS_TRANSACTIONS_TIMEOUT,
    }
  );
};

export function revokeFeature({
  customerId,
  customerType,
  featureId,
}: revokeFeatureRequestParams): Promise<AxiosResponse<CBHResponseData>> {
  return private_client.post(CBH_URLS.revoke_feature, {
    customerId: { type: customerType, value: customerId },
    featureId,
  });
}

export function downloadReceipt({
  createdAtUtc,
  pbpPaymentId,
}: downloadReceiptRequestParams): Promise<AxiosResponse<CBHResponseData>> {
  return private_client.post(
    CBH_URLS.download_receipt,
    {
      pbpPaymentId,
      createdAtUtc,
    },
    { responseType: 'blob' }
  );
}

const acceptDispute = async ({
  disputeId,
}: acceptDisputeParams): Promise<AxiosResponse<CBHResponseData>> => {
  return private_client({
    data: {
      disputeId: disputeId,
    },
    method: 'post',
    url: CBH_URLS.accept_dispute,
  });
};

const getFreebies = async (): Promise<freebiesResponse> => {
  return private_client.post(CBH_URLS.get_freebies);
};
const addFreebie = async ({
  pricePointIdent,
  customerId,
  customerType,
}: {
  pricePointIdent: string;
  customerId: string;
  customerType: customerTypeVariants;
}): Promise<AxiosResponse<addFreebieResponse>> => {
  return private_client.post(CBH_URLS.grant_freebie, {
    pricePointIdent,
    customer: { id: { type: customerType, value: customerId } },
  });
};

export {
  getCustomerPaymentMethods,
  cancelSubscription,
  refundPayment,
  getFeatures,
  getSubscriptions,
  unlinkPaymentMethod,
  getPricePoints,
  getPricePointPayments,
  searchCustomers,
  searchCustomersTransactions,
  acceptDispute,
  getRefundReasons,
  getFreebies,
  addFreebie,
};
